<template>
  <div style="z-index: 0;">
    <v-sheet
      v-if="showSessionTimeAlert && !recentlySendTemplate && !sessionTimeExpired"
      max-width="50%"
      class="text-center"
      style="margin-left: auto; margin-right: auto; border: 1px solid rgb(187, 187, 187); border-radius: 5px;"
    >
      <div class="pb-0 mb-1 pt-1">
        <v-icon
          left
          size="20"
          color="blue"
        >
          fas fa-info-circle
        </v-icon>
        <span style="font-size: 12px;">
          <span v-if="diffTimeCreatedSessionHours > 0">
            <span v-if="diffTimeCreatedSessionHours > 1">{{ $t('Te quedan') }}</span>
            <span v-else>{{ $t('Te queda') }}</span>
            <b> {{ diffTimeCreatedSessionHours }} </b>
            <span v-if="diffTimeCreatedSessionHours > 1">{{ $t('horas') }}</span>
            <span v-else>{{ $t('hora') }}</span>
          </span>
          <span v-else>
            <span v-if="diffTimeCreatedSessionMinutes > 1">{{ $t('Te quedan') }}</span>
            <span v-else>{{ $t('Te queda') }}</span>
            <b> {{ diffTimeCreatedSessionMinutes }} </b>
            <span v-if="diffTimeCreatedSessionMinutes > 1">{{ $t('minutos') }}</span>
            <span v-else>{{ $t('minuto') }}</span>
          </span>
          {{ $t('de conversación. Después de esto, sólo podrás enviar mensajes usando plantillas') }}
        </span>
      </div>
    </v-sheet>
    <v-sheet
      v-else-if="recentlySendTemplate || sessionTimeExpired && !isBlocklistedInbox"
      width="55%"
      class="pt-2 pb-2 px-8"
      style="margin-left: auto; margin-right: auto; border: 1px solid rgb(187, 187, 187); border-radius: 5px;"
    >
      <v-row>
        <v-col>
          <i
            class="mt-1 fa fa-info-circle blue--text float-left mr-2"
            style="font-size: 22px;"
          />
          <p
            style="text-align: justify; font-size: 12px; line-height: 1.3em;"
            class="mb-0"
          >
            {{ $t('Recuerda que solo puedes hablar con el contacto si te escribe un mensaje o responde a una plantilla enviada recientmente') }}.
          </p>
        </v-col>
      </v-row>
      <v-row class="mt-0 pt-0">
        <v-col class="text-center">
          <v-btn
            color="blue"
            dark
            rounded
            small
            elevation="0"
            @click="openQuickMessagesNoFastResponses"
          >
            {{ $t('Enviar plantilla') }}
          </v-btn>

          <v-btn
            color="blue"
            dark
            rounded
            small
            elevation="0"
            class="ml-3"
            @click="createWhatsappTemplate"
          >
            {{ $t('Crear plantilla') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-sheet>
    <v-sheet
      v-if="showOtherActions"
      style="height: 80px; margin-top: 25px;"
      class="pl-2"
    >
      <v-row>
        <v-col cols="6">
          <v-row>
            <v-col
              cols="2"
              class="text-center"
            >
              <v-btn
                icon
                color="light-blue darken-2"
                class="mt-0"
                elevation="0"
                @click="showMessageInteractiveDialog"
              >
                <v-icon
                  size="22"
                >
                  fas fa-comment-dots
                </v-icon>
              </v-btn>
              <p style="font-size: 10px;">
                {{ $t('Mensaje interactivo') }}
              </p>
            </v-col>
            <v-col
              cols="2"
              class="text-center"
            >
              <v-btn
                icon
                color="light-blue darken-2"
                class="mt-0"
                elevation="0"
                @click="showLocationDialog"
              >
                <v-icon
                  size="22"
                >
                  fas fa-location-dot
                </v-icon>
              </v-btn>
              <p style="font-size: 10px;">
                {{ $t('Localización') }}
              </p>
            </v-col>
            <v-col
              cols="2"
              class="text-center"
            >
              <button-multimedia
                icon-class="fas fa-paperclip"
                :icon="true"
                :button-text="$t('Gestor Multimedia')"
                :directories="['imagenes', 'videos', , 'audios', 'adjuntos', 'csv']"
                @click="openMultimedia"
                @onSelectedMultimediaFile="selectMultimediaFile"
                @onSelectedCsv="selectMultimediaFile"
              />
              <p style="font-size: 10px;">
                {{ $t('Adjuntar') }}
              </p>
            </v-col>
            <v-col
              cols="2"
              class="text-center"
            >
              <v-btn
                icon
                color="light-blue darken-2"
                class="mt-0"
                elevation="0"
                @click="openContactNote"
              >
                <v-icon
                  size="22"
                >
                  fa-solid fa-file-medical
                </v-icon>
              </v-btn>
              <p style="font-size: 10px;">
                {{ $t('Agregar nota') }}
              </p>
            </v-col>
            <v-col
              cols="2"
              class="text-center"
            >
              <v-btn
                icon
                color="light-blue darken-2"
                class="mt-0"
                elevation="0"
                @click="openContactDialog"
              >
                <v-icon
                  size="22"
                >
                  fa-solid fa-tag
                </v-icon>
              </v-btn>
              <p style="font-size: 10px;">
                {{ $t('Etiquetar') }}
              </p>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="5" />
        <v-col cols="1">
          <v-btn
            icon
            class="float-right"
            style="margin-top: -10px;"
            @click="showOtherActions = false"
          >
            <v-icon size="15">
              fas fa-close
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-sheet>
    <div
      v-show="Object.keys(room).length && showFooter"
      id="room-footer"
      class="vac-room-footer"
      :class="{
        'vac-app-box-shadow': shadowFooter
      }"
    >
      <room-emojis
        :filtered-emojis="filteredEmojis"
        :select-item="selectEmojiItem"
        :active-up-or-down="activeUpOrDownEmojis"
        @select-emoji="selectEmoji($event)"
        @activate-item="activeUpOrDownEmojis = 0"
      />

      <room-users-tag
        :filtered-users-tag="filteredUsersTag"
        :select-item="selectUsersTagItem"
        :active-up-or-down="activeUpOrDownUsersTag"
        @select-user-tag="selectUserTag($event)"
        @activate-item="activeUpOrDownUsersTag = 0"
      />

      <room-templates-text
        :filtered-templates-text="filteredTemplatesText"
        :select-item="selectTemplatesTextItem"
        :active-up-or-down="activeUpOrDownTemplatesText"
        @select-template-text="selectTemplateText($event)"
        @activate-item="activeUpOrDownTemplatesText = 0"
      />

      <room-message-reply
        :room="room"
        :message-reply="messageReply"
        :text-formatting="textFormatting"
        :link-options="linkOptions"
        @reset-message="resetMessage"
      >
        <template
          v-for="(i, name) in $slots"
          #[name]="data"
        >
          <slot
            :name="name"
            v-bind="data"
          />
        </template>
      </room-message-reply>

      <room-files
        :files="files"
        @remove-file="removeFile"
        @reset-message="resetMessage"
      >
        <template
          v-for="(i, name) in $slots"
          #[name]="data"
        >
          <slot
            :name="name"
            v-bind="data"
          />
        </template>
      </room-files>

      <div
        class="vac-box-footer pt-0"
        :class="{ 'vac-box-footer-border': !files.length }"
      >
        <div
          v-if="showAudio && !files.length"
          class="vac-icon-textarea-left"
        >
          <template v-if="isRecording">
            <div
              class="vac-svg-button vac-icon-audio-stop"
              @click="stopRecorder"
            >
              <slot name="audio-stop-icon">
                <svg-icon name="close-outline" />
              </slot>
            </div>

            <div class="vac-dot-audio-record" />

            <div class="vac-dot-audio-record-time">
              {{ recordedTime }}
            </div>

            <div
              class="vac-svg-button vac-icon-audio-confirm"
              @click="toggleRecorder(false)"
            >
              <slot name="audio-check-icon">
                <svg-icon name="checkmark" />
              </slot>
            </div>
          </template>

          <!-- <div
            v-else
            class="vac-svg-button"
            @click="toggleRecorder(true)"
          >
            <slot name="microphone-icon">
              <svg-icon
                name="microphone"
                class="vac-icon-microphone"
              />
            </slot>
          </div> -->
        </div>

        <div
          class="vac-icon-textarea"
        >
          <div v-if="!disableSendMessage">
            <div
              v-if="showEmojis"
              v-click-outside="() => (emojiOpened = false)"
            >
              <slot
                name="emoji-picker"
                v-bind="{ emojiOpened }"
                :add-emoji="addEmoji"
              >
                <emoji-picker-container
                  :emoji-opened="emojiOpened"
                  :position-top="true"
                  :emoji-data-source="emojiDataSource"
                  @add-emoji="addEmoji"
                  @open-emoji="emojiOpened = $event"
                >
                  <template #emoji-picker-icon>
                    <slot name="emoji-picker-icon" />
                  </template>
                </emoji-picker-container>
              </slot>
            </div>
          </div>
          <v-tooltip top>
            <template #activator="{ on, attrs }">
              <v-btn
                icon
                color="light-blue darken-2"
                class="mt-0"
                :disabled="disableSendMessage"
                elevation="0"
                v-bind="attrs"
                @click="showOtherActions = !showOtherActions"
                v-on="on"
              >
                <v-icon
                  size="22"
                >
                  fas fa-circle-plus
                </v-icon>
              </v-btn>
            </template>
            <span>{{ $t('Ver otras acciones') }}</span>
          </v-tooltip>
          <v-tooltip top>
            <template #activator="{ on, attrs }">
              <v-btn
                icon
                color="light-blue darken-2"
                class="mt-0"
                elevation="0"
                v-bind="attrs"
                :disabled="disableSendMessage"
                @click="showNiaWhatsappAssistant"
                v-on="on"
              >
                <v-img
                  :src="require('@/assets/img/nia/nia.png')"
                  max-width="28px"
                />
              </v-btn>
            </template>
            <span>NIA</span>
          </v-tooltip>
        </div>

        <div style="width: 100%; padding-right: 65px; margin-top: 5px;">
          <v-btn
            icon
            color="blue"
            :dark="!isBlocklistedInbox"
            style="position: absolute;"
            class="mt-1 ml-3"
            :disabled="isBlocklistedInbox"
            @click="openQuickMessages"
          >
            <v-icon size="20">
              fa-solid fa-copy
            </v-icon>
          </v-btn>
          <v-btn-toggle
            v-show="textProps.show"
            :style="{
              'position': 'absolute',
              'left': textProps.x +'px',
              'top': '-20px'
            }"
          >
            <v-tooltip top>
              <template #activator="{ on, attrs }">
                <v-btn
                  small
                  v-bind="attrs"
                  color="white"
                  class="btn-action-whatsapp-sender border-top-r-radius-0"
                  v-on="on"
                  @click="addFormatText('strong')"
                >
                  <i class="fas fa-bold pr-2 ico-mini" />
                </v-btn>
              </template>
              <span>{{ $t('Negrita') }}</span>
            </v-tooltip>
            <v-tooltip top>
              <template #activator="{ on, attrs }">
                <v-btn
                  small
                  v-bind="attrs"
                  color="white"
                  class="btn-action-whatsapp-sender border-top-r-radius-0"
                  v-on="on"
                  @click="addFormatText('italic')"
                >
                  <i class="fas fa-italic pr-2 ico-mini" />
                </v-btn>
              </template>
              <span>{{ $t('Cursiva') }}</span>
            </v-tooltip>
            <v-tooltip top>
              <template #activator="{ on, attrs }">
                <v-btn
                  small
                  v-bind="attrs"
                  color="white"
                  class="btn-action-whatsapp-sender border-top-r-radius-0"
                  v-on="on"
                  @click="addFormatText('strike')"
                >
                  <i class="fas fa-strikethrough pr-2 ico-mini" />
                </v-btn>
              </template>
              <span>{{ $t('Tachado') }}</span>
            </v-tooltip>
            <v-tooltip top>
              <template #activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  small
                  color="white"
                  class="btn-action-whatsapp-sender border-top-r-radius-0"
                  v-on="on"
                  @click="addFormatText('list')"
                >
                  <i class="fas fa-list pr-2 ico-mini" />
                </v-btn>
              </template>
              <span>{{ $t('lista') }}</span>
            </v-tooltip>
            <v-tooltip top>
              <template #activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  small
                  color="white"
                  class="btn-action-whatsapp-sender border-top-r-radius-0"
                  v-on="on"
                  @click="addFormatText('numberedList')"
                >
                  <i class="fas fa-list-ol pr-2 ico-mini" />
                </v-btn>
              </template>
              <span>
                {{ $t('lista') }}
              </span>
            </v-tooltip>
            <v-tooltip top>
              <template #activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  small
                  color="white"
                  class="btn-action-whatsapp-sender border-top-r-radius-0"
                  v-on="on"
                  @click="addFormatText('quote')"
                >
                  <i class="fas fa-quote-left pr-2 ico-mini" />
                </v-btn>
              </template>
              <span>{{ $t('Cita') }}</span>
            </v-tooltip>
            <v-tooltip top>
              <template #activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  small
                  color="white"
                  class="btn-action-whatsapp-sender border-top-r-radius-0"
                  v-on="on"
                  @click="addFormatText('aligned')"
                >
                  <i class="fa-solid fa-angle-left" />
                  <i class="fas fa-ellipsis ico-mini" />
                  <i class="fa-solid fa-angle-right" />
                </v-btn>
              </template>
              <span>{{ $t('Aligned') }}</span>
            </v-tooltip>
            <v-tooltip top>
              <template #activator="{ on, attrs }">
                <v-btn
                  small
                  v-bind="attrs"
                  color="white"
                  class="btn-action-whatsapp-sender border-top-r-radius-0"
                  v-on="on"
                  @click="addFormatText('monospace')"
                >
                  &#060;&#047;&#062;
                </v-btn>
              </template>
              <span>{{ $t('Monoespacio') }}</span>
            </v-tooltip>
          </v-btn-toggle>
          <div
            :style="{
              'border-style': `solid`,
              'border-width': '1px',
              'border-color': '#e1e4e8',
              'border-radius': `40px`,
              'line-height': `14px`,
              'padding': '1px 0px 0px 1px',
              'width': '105%',
              'height': '95%'
            }"
          >
            <!-- :disabled="disableSendMessage" -->
            <textarea
              id="roomTextarea"
              ref="roomTextarea"
              v-click-outside="outTextAreaMessage"
              :placeholder="$t('Escribir mensaje')"
              class="vac-textarea"
              :class="{
                'vac-textarea-outline': editedMessage._id
              }"
              :style="{
                'min-height': `20px`,
                'padding-left': `45px`,
                'font-size': `14px`,
                'line-height': `14px`,
                'width': '90%',
                'margin-left': '1px',
                'margin-bottom': '1px',
                'margin-top': '-1px',
                'border-style': 'none'
              }"
              @input="onChangeInput"
              @keydown.esc="escapeTextarea"
              @keydown.enter.exact.prevent="selectItem"
              @paste="onPasteImage"
              @keydown.tab.exact.prevent=""
              @keydown.tab="selectItem"
              @keydown.up="updateActiveUpOrDown($event, -1)"
              @keydown.down="updateActiveUpOrDown($event, 1)"
            />
          </div>
          <v-menu>
            <template #activator="{ on: menu, attrs }">
              <v-tooltip top>
                <template #activator="{ on }">
                  <v-btn
                    style="position: absolute; right: 80px !important; top: 15px !important;"
                    small
                    icon
                    v-bind="attrs"
                    class="border-top-l-radius-0"
                    v-on="{ ...on, ...menu }"
                  >
                    { }
                  </v-btn>
                </template>
                <span>{{ $t('Campos personalizados') }}</span>
              </v-tooltip>
            </template>
            <v-list>
              <v-subheader class="selector-header">
                {{ $t('Campos personalizados') }}
              </v-subheader>
              <v-virtual-scroll
                :items="contactCustomFields"
                height="230"
                item-height="45"
              >
                <template #default="{ item }">
                  <v-list-item
                    :key="item.value"
                    @click="addFieldToMessage(item.value)"
                  >
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ item.text }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-virtual-scroll>
            </v-list>
          </v-menu>
        </div>

        <div class="vac-icon-textarea">
          <div
            v-if="editedMessage._id"
            class="vac-svg-button"
            @click="resetMessage"
          >
            <slot name="edit-close-icon">
              <svg-icon name="close-outline" />
            </slot>
          </div>


          <!-- <div
            v-if="showFiles"
            class="vac-svg-button"
            @click="launchFilePicker"
          >
            <slot name="paperclip-icon">
              <svg-icon name="paperclip" />
            </slot>
          </div> -->

          <!-- <div
            v-if="showFiles"
            class="vac-svg-button"
            @click="launchMultimediaManager"
          >
            <slot name="paperclip-icon">
              <svg-icon name="paperclip" />
            </slot>
          </div> -->
          <div
            v-if="textareaActionEnabled"
            class="vac-svg-button"
            @click="textareaActionHandler"
          >
            <slot name="custom-action-icon">
              <svg-icon name="deleted" />
            </slot>
          </div>

          <input
            v-if="showFiles"
            ref="file"
            type="file"
            multiple
            :accept="acceptedFiles"
            :capture="captureFiles"
            style="display: none"
            @change="onFileChange($event.target.files)"
          >

          <div
            v-if="showSendIcon"
            class="vac-svg-button"
            :class="{ 'vac-send-disabled': isMessageEmpty }"
            @click="sendMessage"
          >
            <slot name="send-icon">
              <svg-icon
                name="send"
                :param="disableSendMessage || sessionTimeExpired || isMessageEmpty || isFileLoading ? 'disabled' : ''"
              />
            </slot>
          </div>
        </div>
      </div>

      <div
        v-show="showQuickResponses"
        style="overflow-y: auto; height: 200px;"
      >
        <table
          v-show="showFastResponses"
          class="sticky-table"
        >
          <thead>
            <tr>
              <th>
                <v-subheader>
                  <b>
                    {{ $t('Respuestas rápidas') }}
                  </b>
                </v-subheader>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(quickResponseitem, index) in quickResponsesitems"
              v-show="isOnSearch(quickResponseitem.responseShort)"
              :key="'response-list-item-'+index"
              @click="changeSelectedQuickResponse(index)"
            >
              <td class="pl-5">
                <b>/{{ quickResponseitem.responseShort }}</b>
                <br>
                {{ quickResponseitem.responseFull }}
              </td>
            </tr>
          </tbody>
        </table>
        <table
          class="sticky-table"
        >
          <thead>
            <tr>
              <th>
                <v-subheader>
                  <b>
                    {{ $t('Plantillas de mensajes') }}
                  </b>
                </v-subheader>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(validTemplate, index) in filteredValidTemplates"
              v-show="isOnSearch(validTemplate.name)"
              :key="'response-list-item-'+index"
              @click="changeSelectedValidTemplate(index)"
            >
              <td class="pl-5">
                <b>/{{ validTemplate.name }}</b>
                <v-chip
                  small
                  class="ma-2"
                >
                  {{ $t(validTemplate.template_whatsapp_category.name) }}
                </v-chip>
                <v-chip
                  small
                  class="ma-2"
                >
                  {{ $t(validTemplate.template_whatsapp_language.name) }}
                </v-chip>
                <br>
                {{ validTemplate.message }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <v-btn
        v-show="showQuickResponses"
        icon
        style="position: absolute; right: 20px; bottom: 155px; z-index: 1000;"
        class="float-right mr-n1"
        @click="closeQuickResponses"
      >
        <v-icon size="15">
          fas fa-close
        </v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
  import moment from 'moment'
  import { Database } from 'emoji-picker-element'

  import SvgIcon from '../../../components/SvgIcon/SvgIcon'
  import EmojiPickerContainer from '../../../components/EmojiPickerContainer/EmojiPickerContainer'

  import RoomFiles from './RoomFiles/RoomFiles'
  import RoomMessageReply from './RoomMessageReply/RoomMessageReply'
  import RoomUsersTag from './RoomUsersTag/RoomUsersTag'
  import RoomEmojis from './RoomEmojis/RoomEmojis'
  import RoomTemplatesText from './RoomTemplatesText/RoomTemplatesText'

  import vClickOutside from 'v-click-outside'
  import filteredItems from '../../../utils/filter-items'
  import Recorder from '../../../utils/recorder'

  import { detectChrome } from '../../../utils/browser-detection'
  import { detectMobile } from '../../../utils/mobile-detection'

  import InboxConfigService from '@/services/whatsapp/inbox-config.service.js'
  import { UserInboxQuickResponses } from '@/models/whatsapp/UserInboxQuickResponses.js'
  import WhatsappTemplateService from '@/services/whatsapp-template.service.js'
  import ButtonMultimedia from '@/components/MultimediaManager/ButtonMultimedia/ButtonMultimedia.vue'
  import getEnv from '@/util/env'
  import EventBus from '@/util/EventBus.js'
  import MultimediaService from '@/services/multimedia.service'
  import HeadboardOption from '@/models/whatsapp-editor/HeadBoardOption'
  import { BlocklistType } from '@/models/whatsapp/BlocklistType'
  import { TextTag } from '@/classes/TextTag'
  import { ChatProps } from '@/classes/chat/chat-props.js'
  import $t from '@/util/Translator.js'

  export default {
    name: 'RoomFooter',

    components: {
      SvgIcon,
      EmojiPickerContainer,
      RoomFiles,
      RoomMessageReply,
      RoomUsersTag,
      RoomEmojis,
      RoomTemplatesText,
      ButtonMultimedia,
    },

    directives: {
      clickOutside: vClickOutside.directive,
    },

    props: {
      chatProps: { type: Object, required: true, default: () => { return new ChatProps() } },
      room: { type: Object, required: true },
      roomId: { type: [String, Number], required: true },
      roomMessage: { type: String, default: null },
      textFormatting: { type: Object, required: true },
      linkOptions: { type: Object, required: true },
      textMessages: { type: Object, required: true },
      showSendIcon: { type: Boolean, required: true },
      showFiles: { type: Boolean, required: true },
      showAudio: { type: Boolean, required: true },
      showEmojis: { type: Boolean, required: true },
      showFooter: { type: Boolean, required: true },
      acceptedFiles: { type: String, required: true },
      captureFiles: { type: String, required: true },
      textareaActionEnabled: { type: Boolean, required: true },
      textareaAutoFocus: { type: Boolean, required: true },
      userTagsEnabled: { type: Boolean, required: true },
      emojisSuggestionEnabled: { type: Boolean, required: true },
      templatesText: { type: Array, default: null },
      audioBitRate: { type: Number, required: true },
      audioSampleRate: { type: Number, required: true },
      initReplyMessage: { type: Object, default: null },
      initEditMessage: { type: Object, default: null },
      droppedFiles: { type: Array, default: null },
      emojiDataSource: { type: String, default: undefined },
    },

    emits: [
      'edit-message',
      'send-message',
      'update-edited-message-id',
      'textarea-action-handler',
      'typing-message',
    ],

    data () {
      return {
        message: '',
        editedMessage: {},
        messageReply: null,
        cursorRangePosition: null,
        files: [],
        fileDialog: false,
        selectUsersTagItem: null,
        selectEmojiItem: null,
        selectTemplatesTextItem: null,
        format: 'mp3',
        activeUpOrDownEmojis: null,
        activeUpOrDownUsersTag: null,
        activeUpOrDownTemplatesText: null,
        emojisDB: new Database({ dataSource: this.emojiDataSource }),
        emojiOpened: false,
        keepKeyboardOpen: false,
        filteredEmojis: [],
        filteredUsersTag: [],
        selectedUsersTag: [],
        filteredTemplatesText: [],
        recorder: this.initRecorder(),
        isRecording: false,
        quickResponsesitems: [],
        showQuickResponses: false,
        validTemplates: [],
        enableAttach: false,
        createdAtSession: null,
        expiredAtSession: null,
        expiredAtCSW: null,
        diffTimeCreatedSessionHours: 0,
        diffTimeCreatedSessionMinutes: 0,
        sessionHoursInterval: 0,
        sessionTimeExpired: false,
        showSessionTimeAlert: false,
        showFastResponses: true,
        showOtherActions: false,
        disableSendMessage: false,
        calledMultimedia: false,
        textTag: null,
        textProps: {
          show: false,
          x: 0,
          y: 0,
        },
        aiDrawerCtrl: {
          show: false,
        },
        recentlySendTemplate: false,
      }
    },

    computed: {
      contactCustomFields () {
        return [
          { text: this.$t('Nombre'), value: 'firstName' },
          { text: this.$t('Apellido'), value: 'lastName' },
          { text: this.$t('Email'), value: 'email' },
          { text: this.$t('Teléfono'), value: 'msisdnClient' },
          { text: this.$t('País'), value: 'countryIso' },
        ]
      },
      filteredValidTemplates () {
        return this.validTemplates
        // return this.validTemplates.filter(t => {
        //   return !this.room.blocklist_status?.includes(t.template_whatsapp_category_id)
        // })
      },
      isMessageEmpty () {
        return !this.files.length && !this.message.trim()
      },
      isFileLoading () {
        return this.files.some(e => e.loading)
      },
      recordedTime () {
        return new Date(this.recorder.duration * 1000).toISOString().substr(14, 5)
      },
      shadowFooter () {
        return (
          !!this.filteredEmojis.length ||
          !!this.filteredUsersTag.length ||
          !!this.filteredTemplatesText.length ||
          !!this.files.length ||
          !!this.messageReply
        )
      },
      isBlocklistedInbox () {
        return this.room.blocklist_status?.length > 0 && this.room.blocklist_status.includes(BlocklistType.INBOX_ID)
      },
    },

    watch: {
      roomId () {
        this.resetMessage(true, true)

        if (this.roomMessage) {
          this.message = this.roomMessage
          setTimeout(() => this.onChangeInput())
        }
      },
      message (val) {
        this.getTextareaRef().value = val
      },
      roomMessage: {
        immediate: true,
        handler (val) {
          if (val) this.message = this.roomMessage
        },
      },
      editedMessage (val) {
        this.$emit('update-edited-message-id', val._id)
      },
      initReplyMessage (val) {
        if (val) {
          this.replyMessage(val)
        }
      },
      initEditMessage (val) {
        if (val) {
          this.editMessage(val)
        }
      },
      droppedFiles (val) {
        if (val) {
          this.onFileChange(val)
        }
      },
    },

    mounted () {
      const isMobile = detectMobile()
      let isComposed = true

      this.textTag = new TextTag(document.getElementById('roomTextarea'))

      this.getTextareaRef().addEventListener('mouseup', e => {
        const from = this.textTag.textElement.selectionStart
        const to = this.textTag.textElement.selectionEnd
        let newStart = from
        let newEnd = to

        while (this.textTag.textElement.value.substring(newStart, newStart + 1) === ' ') {
          newStart++
        }

        while (this.textTag.textElement.value.substring(newEnd - 1, newEnd) === ' ') {
          newEnd--
        }

        this.textTag.textElement.setSelectionRange(newStart, newEnd)
        const textSelected = this.textTag.textElement.value.substring(from, to)

        if (textSelected.length > 0) {
          this.textProps.x = e.clientX - 600
          this.textProps.show = true
        } else {
          this.textProps.show = false
        }
      })

      // eslint-disable-next-line complexity
      this.getTextareaRef().addEventListener('keyup', e => {
        if (e.key === 'Enter' && !(e.shiftKey || e.ctrlKey || e.altKey) && !this.fileDialog) {
          if (isMobile) {
            this.message = this.message + '\n'
            setTimeout(() => this.onChangeInput())
          } else if (
            isComposed &&
            !this.filteredEmojis.length &&
            !this.filteredUsersTag.length &&
            !this.filteredTemplatesText.length
          ) {
            this.sendMessage()
          }
        }
        isComposed = !e.isComposing

        setTimeout(() => {
          this.updateFooterLists()
        }, 60)
      })

      this.getTextareaRef().addEventListener('keydown', e => {
        if (e.key === 'Enter' && (e.ctrlKey || e.altKey)) {
          this.message = this.message + '\n'
        }
      })

      this.getTextareaRef().addEventListener('click', () => {
        if (isMobile) this.keepKeyboardOpen = true
        this.updateFooterLists()
      })

      this.getTextareaRef().addEventListener('blur', () => {
        setTimeout(() => {
          this.resetFooterList()
        }, 100)

        if (isMobile) setTimeout(() => (this.keepKeyboardOpen = false))
      })

      EventBus.$on('ChangeCurrentUserMsisdn', this.changeMsisdn)
      EventBus.$on('hook:beforeDestroy', () => {
        EventBus.$off('ChangeCurrentUserMsisdn', this.changeMsisdn)
      })

      EventBus.$on('disableAttachFromFooter', () => {
        this.enableAttach = false
      })

      EventBus.$on('disableAttachFromFooter', () => {
        this.enableAttach = false
      })

      EventBus.$on('ChangeCurrentUserMsisdn', () => {
        this.getValidTemplates()
        this.showSessionTimeAlert = false
      })

      EventBus.$on('enableAttachFromFooter', () => {
        this.enableAttach = true
      })

      EventBus.$on('loadCSWTime', (expiredAt) => {
        // console.log('expire', expiredAt)
        this.showFastResponses = true
        // this.createdAtSession = createdAt
        this.expiredAtCSW = expiredAt
        if (this.sessionHoursInterval) {
          clearInterval(this.sessionHoursInterval)
        }
        this.setTimeInverval24HoursCSW()
      })
      EventBus.$on('hideSessionTimeExpiredAlert', () => {
        this.sessionTimeExpired = false
        this.showSessionTimeAlert = false
        this.showFastResponses = true
      })
      EventBus.$on('Whatsapp:hideQuickResponses', this.closeQuickResponses)
      EventBus.$on('Whatsapp:disableSendMessage', () => {
        this.disableSendMessage = true
      })
      EventBus.$on('Whatsapp:enableSendMessage', () => {
        this.disableSendMessage = false
      })

      EventBus.$on('templateMessage:recentlySendTemplate', (show) => {
        this.recentlySendTemplate = show
      })

      EventBus.$on('inbox:closeOtherActions', () => {
        this.showOtherActions = false
      })
      EventBus.$on('setNiaWhatsAppIdea', this.setAiIdea)
    },

    beforeUnmount () {
      this.stopRecorder()
    },

    methods: {
      showNiaWhatsappAssistant () {
        EventBus.$emit('showNiaWhatsappAssistant')
      },
      changeMsisdn (msisdn) {
        this.getQuickResponses(msisdn)
      },
      setAiIdea (idea) {
        this.message = this.message + idea
      },
      outTextAreaMessage () {
        this.textProps.show = false
      },
      openContactDialog () {
        EventBus.$emit('Chat:OpenContactDialog')
      },
      openContactNote () {
        this.$emit('showNoteDialog')
      },
      addFieldToMessage (field) {
        let myValue = this.room.users[1].contact[field]
        const textarea = this.textTag.textElement
        const sentence = textarea.value
        const len = sentence.length
        let pos = textarea.selectionStart
        if (pos === undefined) {
          pos = 0
        }
        if (myValue === null) {
          myValue = $t('Sin datos')
        }

        const before = sentence.substr(0, pos)
        const after = sentence.substr(pos, len)

        this.message = before + myValue + after
      },
      addFormatText (type) {
        const replaced = this.textTag.addFormatText(type)
        this.message = replaced || this.textTag.textElement.value
      },
      setIsoFlag (isoCode) {
        const iso = isoCode.substr(-2).toLowerCase()
        let lang = iso === 'en' ? 'gb' : iso
        lang = iso === 'sq' ? 'al' : iso
        return require('./../../../../../assets/img/flags/' + lang + '.svg')
      },
      openMultimedia () {
        this.enableAttach = true
        this.calledMultimedia = true
      },
      createWhatsappTemplate () {
        EventBus.$emit('newCampaign', 'whatsapp')
      },
      setTimeInverval24HoursSession () {
        this.showSessionTimeAlert = false
        this.sessionTimeExpired = false
        // const lastSessionDatePlus24Hours = moment(this.createdAtSession).add(24, 'hours')
        const lastSessionDatePlus24Hours = moment(this.expiredAtSession)
        this.sessionHoursInterval = setInterval(() => {
          const now = moment(new Date())
          const currentMilis = parseInt(now.format('x'))
          const lastSession24HoursMilis = parseInt(lastSessionDatePlus24Hours.format('x'))
          if (currentMilis >= lastSession24HoursMilis) {
            clearInterval(this.sessionHoursInterval)
            this.sessionTimeExpired = true
            this.disableSendMessage = true
            this.showOtherActions = false
            this.showFastResponses = false
          } else {
            this.showSessionTimeAlert = true
            const diff = moment.duration(lastSessionDatePlus24Hours.diff(now))
            const miliSeconds = diff.asMilliseconds()
            this.diffTimeCreatedSessionHours = parseInt(moment.utc(miliSeconds).format('HH'))
            this.diffTimeCreatedSessionMinutes = parseInt(moment.utc(miliSeconds).format('mm'))
          }

          // this.room.messagesNumberClient === 0
          if (this.room.isOpenCSW === 0) {
            this.sessionTimeExpired = true
            this.disableSendMessage = true
            this.showFastResponses = false
          }
        }, 1000)
      },
      setTimeInverval24HoursCSW () {
        this.showSessionTimeAlert = false
        this.sessionTimeExpired = false
        const lastCSWDatePlus24Hours = (!this.expiredAtCSW) ? null : moment(this.expiredAtCSW)
        this.sessionHoursInterval = setInterval(() => {
          const now = moment(new Date())
          const currentMilis = parseInt(now.format('x'))
          const lastCSW24HoursMilis = (!lastCSWDatePlus24Hours) ? null : parseInt(lastCSWDatePlus24Hours.format('x'))
          if (!lastCSW24HoursMilis || (currentMilis >= lastCSW24HoursMilis)) {
            clearInterval(this.sessionHoursInterval)
            this.sessionTimeExpired = true
            this.disableSendMessage = true
            this.showOtherActions = false
            this.showFastResponses = false
          } else {
            this.showSessionTimeAlert = true
            const diff = moment.duration(lastCSWDatePlus24Hours.diff(now))
            const miliSeconds = diff.asMilliseconds()
            this.diffTimeCreatedSessionHours = parseInt(moment.utc(miliSeconds).format('HH'))
            this.diffTimeCreatedSessionMinutes = parseInt(moment.utc(miliSeconds).format('mm'))
          }

          // this.room.messagesNumberClient === 0
          if (this.room.isOpenCSW === 0) {
            this.sessionTimeExpired = true
            this.disableSendMessage = true
            this.showFastResponses = false
          }
        }, 1000)
      },
      showLocationDialog () {
        this.$emit('showLocationDialog')
      },
      showMessageInteractiveDialog () {
        this.$emit('showMessageInteractiveDialog')
      },
      // eslint-disable-next-line complexity
      async selectMultimediaFile (url, fileInfo) {
        if (!this.calledMultimedia) {
          return
        }
        this.calledMultimedia = false

        if (!this.enableAttach) return
        console.log(url, fileInfo)

        const [simpleType, tooBig, sizeTooBig] = HeadboardOption.fileType(fileInfo)

        if (simpleType === 'audio') {
          // Translate wav to mp3
          await MultimediaService.getMp3FromAudio({
            filename: fileInfo.name + '.' + fileInfo.extension,
          })
            .then(response => {
              url = response.url
              fileInfo = response.fileInfo
            })
        }

        if (tooBig) {
          EventBus.$emit('showAlert', 'danger', this.$t('Has elegido un fichero demasiado grande. El tamaño máximo para este tipo de fichero es ') + sizeTooBig + 'MB.')
        } else {
          if (!simpleType?.length) {
            EventBus.$emit('showAlert', 'danger', this.$t('Este tipo de fichero no puede ser enviado a través de WhatsApp'))
          } else {
            this.files.push({
              loading: false,
              name: fileInfo.name,
              size: fileInfo.size,
              type: fileInfo.mimeType,
              simpleType: simpleType,
              extension: fileInfo.extension,
              localUrl: getEnv('VUE_APP_ASSETS_URL') + url,
            })
          }
        }
      },
      tooBig (sizeInBytes, mb) {
        return this.bytesToMB(sizeInBytes) > mb
      },
      bytesToMB (sizeInBytes) {
        return (sizeInBytes / (1024 * 1024)).toFixed(2)
      },
      cleanText (text) {
        return text.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').trim()
      },

      isOnSearch (textProperty) {
        if (this.message.charAt(0) === '/') {
          const parts = this.message.split('/')
          const searchString = parts[1]
          if (searchString === '') return true
          return this.cleanText(textProperty).includes(this.cleanText(searchString))
        }

        return true
      },
      openQuickMessagesNoFastResponses () {
        this.showFastResponses = false
        this.openQuickMessages()
      },
      openQuickMessages () {
        this.message = '/'
        setTimeout(() => {
          this.onChangeInput()
        })
      },
      getValidTemplates () {
        WhatsappTemplateService.getValidTemplates({
          msisdn: this.chatProps.currentUserMsisdn,
        }).then((validTemplates) => {
          this.validTemplates = validTemplates.map(t => {
            t.head_board = JSON.parse(t.head_board)
            return t
          })
        })
      },
      getQuickResponses (msisdn) {
        this.quickResponsesitems = []
        InboxConfigService.allResponses({ msisdn }).then((response) => {
          response.forEach(response => {
            this.quickResponsesitems.push(new UserInboxQuickResponses(response))
          })
        })
      },
      changeSelectedQuickResponse (index) {
        const contactFields = {
          firstname: 'firstName',
          lastname: 'lastName',
          email: 'email',
          phone: 'msisdnClient',
        }

        let messageToParse = this.quickResponsesitems[index].responseFull
        Object.keys(contactFields).forEach(key => {
          const value = this.room.users[1].contact[contactFields[key]]
          const search = '{' + key + '}'
          messageToParse = messageToParse.replaceAll(search, value)
        })

        this.message = messageToParse
        this.calculateRows()
      },

      changeSelectedValidTemplate (index) {
        this.$emit('checkShowValidTemplate', this.filteredValidTemplates[index])
      },

      getTextareaRef () {
        return this.$refs.roomTextarea
      },
      focusTextarea (disableMobileFocus) {
        if (detectMobile() && disableMobileFocus) return
        if (!this.getTextareaRef()) return
        this.getTextareaRef().focus()

        if (this.cursorRangePosition) {
          setTimeout(() => {
            const offset = detectChrome() ? 0 : 1
            this.getTextareaRef().setSelectionRange(
              this.cursorRangePosition + offset,
              this.cursorRangePosition + offset,
            )
            this.cursorRangePosition = null
          })
        }
      },
      onChangeInput () {
        if (this.getTextareaRef()?.value || this.getTextareaRef()?.value === '') {
          this.message = this.getTextareaRef()?.value
        }
        if (this.sessionHoursInterval) {
          clearInterval(this.sessionHoursInterval)
          this.showSessionTimeAlert = false
        }
        this.keepKeyboardOpen = true
        this.resizeTextarea()
        this.$emit('typing-message', this.message)

        this.calculateRows()

        this.showQuickResponses = this.message.charAt(0) === '/'
      },
      calculateRows () {
        const textarea = this.getTextareaRef()
        textarea.style.height = this.calcHeight(this.message) + 'px'
      },
      calcHeight (value) {
        const numberOfLineBreaks = (value.match(/\n/g) || []).length
        // min-height + lines x line-height + padding + border
        const newHeight = 10 + numberOfLineBreaks * 10 + 12 + 2
        return newHeight > 100 ? 100 : newHeight
      },
      resizeTextarea () {
        const el = this.getTextareaRef()

        if (!el) return

        const padding = window
          .getComputedStyle(el, null)
          .getPropertyValue('padding-top')
          .replace('px', '')

        el.style.height = 0
        el.style.height = el.scrollHeight - padding * 2 + 'px'
      },
      escapeTextarea () {
        if (this.filteredEmojis.length) this.filteredEmojis = []
        else if (this.filteredUsersTag.length) this.filteredUsersTag = []
        else if (this.filteredTemplatesText.length) {
          this.filteredTemplatesText = []
        } else this.resetMessage()
      },
      onPasteImage (pasteEvent) {
        const items = pasteEvent.clipboardData?.items

        if (items) {
          Array.from(items).forEach(item => {
            if (item.type.includes('image')) {
              const blob = item.getAsFile()
              this.onFileChange([blob])
            }
          })
        }
      },
      updateActiveUpOrDown (event, direction) {
        if (this.filteredEmojis.length) {
          this.activeUpOrDownEmojis = direction
          event.preventDefault()
        } else if (this.filteredUsersTag.length) {
          this.activeUpOrDownUsersTag = direction
          event.preventDefault()
        } else if (this.filteredTemplatesText.length) {
          this.activeUpOrDownTemplatesText = direction
          event.preventDefault()
        }
      },
      selectItem () {
        if (this.filteredEmojis.length) {
          this.selectEmojiItem = true
        } else if (this.filteredUsersTag.length) {
          this.selectUsersTagItem = true
        } else if (this.filteredTemplatesText.length) {
          this.selectTemplatesTextItem = true
        }
      },
      selectEmoji (emoji) {
        this.selectEmojiItem = false

        if (!emoji) return

        const { position, endPosition } = this.getCharPosition(':')

        this.message =
          this.message.substr(0, position - 1) +
          emoji +
          this.message.substr(endPosition, this.message.length - 1)

        this.cursorRangePosition = position
        this.focusTextarea()
      },
      selectTemplateText (template) {
        this.selectTemplatesTextItem = false

        if (!template) return

        const { position, endPosition } = this.getCharPosition('/')

        const space = this.message.substr(endPosition, endPosition).length
          ? ''
          : ' '

        this.message =
          this.message.substr(0, position - 1) +
          template.text +
          space +
          this.message.substr(endPosition, this.message.length - 1)

        this.cursorRangePosition =
          position + template.text.length + space.length + 1

        this.focusTextarea()
      },
      addEmoji (emoji) {
        const from = this.textTag.textElement.selectionStart
        this.message = this.message.substring(0, from) + emoji.unicode + this.message.substring(from)

        setTimeout(() => {
          const input = document.getElementById('roomTextarea')
          input.focus()
          input.setSelectionRange(from + 2, from + 2)
        })
      },
      launchFilePicker () {
        this.$refs.file.value = ''
        this.$refs.file.click()
      },
      async onFileChange (files) {
        this.fileDialog = true
        this.focusTextarea()

        Array.from(files).forEach(async file => {
          const fileURL = URL.createObjectURL(file)
          const typeIndex = file.name.lastIndexOf('.')
          console.log(file)
          this.files.push({
            loading: true,
            name: file.name.substring(0, typeIndex),
            size: file.size,
            type: file.type,
            extension: file.name.substring(typeIndex + 1),
            localUrl: fileURL,
          })

          const blobFile = await fetch(fileURL).then(res => res.blob())

          const loadedFile = this.files.find(file => file.localUrl === fileURL)

          if (loadedFile) {
            loadedFile.blob = blobFile
            loadedFile.loading = false
            delete loadedFile.loading
          }
        })

        setTimeout(() => (this.fileDialog = false), 500)
      },
      removeFile (index) {
        this.files.splice(index, 1)
        this.focusTextarea()
      },
      toggleRecorder (recording) {
        this.isRecording = recording

        if (!this.recorder.isRecording) {
          setTimeout(() => this.recorder.start(), 200)
        } else {
          try {
            this.recorder.stop()

            const record = this.recorder.records[0]

            this.files.push({
              blob: record.blob,
              name: `audio.${this.format}`,
              size: record.blob.size,
              duration: record.duration,
              type: record.blob.type,
              audio: true,
              localUrl: URL.createObjectURL(record.blob),
            })

            this.recorder = this.initRecorder()
            this.sendMessage()
          } catch {
            setTimeout(() => this.stopRecorder(), 100)
          }
        }
      },
      stopRecorder () {
        if (this.recorder.isRecording) {
          try {
            this.recorder.stop()
            this.recorder = this.initRecorder()
          } catch {
            setTimeout(() => this.stopRecorder(), 100)
          }
        }
      },
      textareaActionHandler () {
        this.$emit('textarea-action-handler', this.message)
      },
      // eslint-disable-next-line complexity
      sendMessage () {
        if (this.sessionTimeExpired) return
        this.showQuickResponses = false
        let message = this.message.trim()

        if (!this.files.length && !message) return

        if (this.isFileLoading) return

        this.selectedUsersTag.forEach(user => {
          message = message.replace(
            `@${user.username}`,
            `<usertag>${user._id}</usertag>`,
          )
        })

        const files = this.files.length ? this.files : null

        if (this.editedMessage._id) {
          if (
            this.editedMessage.content !== message ||
            this.editedMessage.files?.length ||
            this.files.length
          ) {
            this.$emit('edit-message', {
              messageId: this.editedMessage._id,
              newContent: message,
              files: files,
              replyMessage: this.messageReply,
              usersTag: this.selectedUsersTag,
            })
          }
        } else {
          const isQuickTemplate = this.substituteQuickTemplate(message)
          if (!isQuickTemplate) {
            message = this.substituteQuickResponses(message)
            this.$emit('send-message', {
              content: message,
              files: files,
              replyMessage: this.messageReply,
              usersTag: this.selectedUsersTag,
            })
          }
        }

        this.resetMessage(true)
      },
      substituteQuickResponses (message) {
        if (message.charAt(0) === '/') {
          const quick = this.quickResponsesitems.find(qr => {
            return qr.responseShort === message.substr(1)
          })
          if (quick) {
            return quick.responseFull
          }
        }
        return message
      },
      substituteQuickTemplate (message) {
        if (message.charAt(0) === '/') {
          const templateIdx = this.filteredValidTemplates.findIndex(vt => {
            return vt.name === message.substr(1)
          })
          if (templateIdx > -1) {
            this.changeSelectedValidTemplate(templateIdx)
            return true
          }
        }
        return false
      },
      editMessage (message) {
        this.resetMessage()

        this.editedMessage = { ...message }

        let messageContent = message.content
        const initialContent = messageContent

        const firstTag = '<usertag>'
        const secondTag = '</usertag>'

        const usertags = [
          ...messageContent.matchAll(new RegExp(firstTag, 'gi')),
        ].map(a => a.index)

        usertags.forEach(index => {
          const userId = initialContent.substring(
            index + firstTag.length,
            initialContent.indexOf(secondTag, index),
          )

          const user = this.room.users.find(user => user._id === userId)

          messageContent = messageContent.replace(
            `${firstTag}${userId}${secondTag}`,
            `@${user?.username || 'unknown'}`,
          )

          this.selectUserTag(user, true)
        })

        this.message = messageContent

        if (message.files) {
          this.files = [...message.files]
        }

        setTimeout(() => this.resizeTextarea())
      },
      replyMessage (message) {
        this.editedMessage = {}
        this.messageReply = message
        this.focusTextarea()
      },
      updateFooterLists () {
        this.updateFooterList('@')
        this.updateFooterList(':')
        this.updateFooterList('/')
      },
      // eslint-disable-next-line complexity
      updateFooterList (tagChar) {
        if (!this.getTextareaRef()) return

        if (tagChar === ':' && !this.emojisSuggestionEnabled) {
          return
        }

        if (tagChar === '@' && (!this.userTagsEnabled || !this.room.users)) {
          return
        }

        if (tagChar === '/' && !this.templatesText) {
          return
        }

        const textareaCursorPosition = this.getTextareaRef().selectionStart

        let position = textareaCursorPosition

        while (
          position > 0 &&
          this.message.charAt(position - 1) !== tagChar &&
          // eslint-disable-next-line no-unmodified-loop-condition
          (this.message.charAt(position - 1) !== ' ' || tagChar !== ':')
        ) {
          position--
        }

        const beforeTag = this.message.charAt(position - 2)
        const notLetterNumber = !beforeTag.match(/^[0-9a-zA-Z]+$/)

        if (
          this.message.charAt(position - 1) === tagChar &&
          (!beforeTag || beforeTag === ' ' || notLetterNumber)
        ) {
          const query = this.message.substring(position, textareaCursorPosition)
          if (tagChar === ':') {
            this.updateEmojis(query)
          } else if (tagChar === '@') {
            this.updateShowUsersTag(query)
          } else if (tagChar === '/') {
            this.updateShowTemplatesText(query)
          }
        } else {
          this.resetFooterList(tagChar)
        }
      },
      updateShowUsersTag (query) {
        this.filteredUsersTag = filteredItems(
          this.room.users,
          'username',
          query,
          true,
        ).filter(user => user._id !== this.currentUserId)
      },
      selectUserTag (user, editMode = false) {
        this.selectUsersTagItem = false

        if (!user) return

        const { position, endPosition } = this.getCharPosition('@')

        const space = this.message.substr(endPosition, endPosition).length
          ? ''
          : ' '

        this.message =
          this.message.substr(0, position) +
          user.username +
          space +
          this.message.substr(endPosition, this.message.length - 1)

        this.selectedUsersTag = [...this.selectedUsersTag, { ...user }]

        if (!editMode) {
          this.cursorRangePosition =
            position + user.username.length + space.length + 1
        }

        this.focusTextarea()
      },
      updateShowTemplatesText (query) {
        this.filteredTemplatesText = filteredItems(
          this.templatesText,
          'tag',
          query,
          true,
        )
      },
      getCharPosition (tagChar) {
        const cursorPosition = this.getTextareaRef().selectionStart

        let position = cursorPosition
        while (position > 0 && this.message.charAt(position - 1) !== tagChar) {
          position--
        }

        const endPosition = this.getTextareaRef().selectionEnd

        return { position, endPosition }
      },
      async updateEmojis (query) {
        if (!query) return

        const emojis = await this.emojisDB.getEmojiBySearchQuery(query)
        this.filteredEmojis = emojis.map(emoji => emoji.unicode)
      },
      resetFooterList (tagChar = null) {
        if (tagChar === ':') {
          this.filteredEmojis = []
        } else if (tagChar === '@') {
          this.filteredUsersTag = []
        } else if (tagChar === '/') {
          this.filteredTemplatesText = []
        } else {
          this.filteredEmojis = []
          this.filteredUsersTag = []
          this.filteredTemplatesText = []
        }
      },
      resetMessage (disableMobileFocus = false, initRoom = false) {
        if (!initRoom) {
          this.$emit('typing-message', null)
        }

        this.selectedUsersTag = []
        this.resetFooterList()
        this.resetTextareaSize()
        this.message = ''
        this.editedMessage = {}
        this.messageReply = null
        this.files = []
        this.emojiOpened = false
        this.preventKeyboardFromClosing()

        if (this.textareaAutoFocus || !initRoom) {
          setTimeout(() => this.focusTextarea(disableMobileFocus))
        }
      },
      resetTextareaSize () {
        if (this.getTextareaRef()) {
          this.getTextareaRef().style.height = '20px'
        }
      },
      preventKeyboardFromClosing () {
        if (this.keepKeyboardOpen) this.getTextareaRef().focus()
      },
      initRecorder () {
        this.isRecording = false

        return new Recorder({
          bitRate: Number(this.audioBitRate),
          sampleRate: Number(this.audioSampleRate),
          beforeRecording: null,
          afterRecording: null,
          pauseRecording: null,
          micFailed: this.micFailed,
        })
      },
      micFailed () {
        this.isRecording = false
        this.recorder = this.initRecorder()
      },
      closeQuickResponses () {
        this.showQuickResponses = false
        this.message = ''
      },
    },
  }
</script>

<style scoped>
.img-flag {
  cursor: pointer;
  display: inline-block;
  margin-left: 3px;
  margin-right: 3px;
  opacity: 1;
  vertical-align: middle;
  width: 24px !important;
}

.btn-action-whatsapp-sender {
  width: 16% !important;
  border-top: none !important;
  color: #363c43 !important;
  font-size: 11px !important;
}
</style>
