<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div
    v-show="showRoomsList"
    class="vac-rooms-container"
    :class="{
      'vac-rooms-container-full': isMobile,
      'vac-app-border-r': !isMobile
    }"
  >
    <slot name="rooms-header" />
    <v-row class="mb-0 pb-0">
      <v-col
        cols="12"
        class="mb-0 pb-0"
      >
        <v-select
          v-model="chatProps.currentUserMsisdn"
          :items="routes.list"
          :label="$t('Selecciona un remitente')"
          item-text="displayName"
          item-value="msisdn"
          elevation="0"
          solo
          class="mb-0 pb-0 pt-2 px-4 oulined-input"
          :style="{ height: '30px' }"
          @change="changeCurrentMsisdn"
        />
      </v-col>
    </v-row>

    <v-row class="d-flex align-center flex-column pl-4 pr-4 pb-2 pt-6 bg-grey-lighten-4">
      <v-btn-toggle
        rounded
        mandatory
      >
        <v-btn
          elevation="0"
          class="btn-conversaciones-whats"
          @click="setOpened"
        >
          {{ $t('Abiertas') }}
        </v-btn>
        <v-btn
          elevation="0"
          class="btn-conversaciones-whats"
          @click="setClosed"
        >
          {{ $t('Cerradas') }}
        </v-btn>
        <v-btn
          elevation="0"
          class="btn-conversaciones-whats"
          @click="setPending"
        >
          {{ $t('Pendientes') }}
        </v-btn>
      </v-btn-toggle>
    </v-row>

    <slot name="rooms-list-search">
      <rooms-search
        :rooms="rooms"
        :loading-rooms="loadingRooms"
        :text-messages="textMessages"
        :show-search="showSearch"
        :show-add-room="showAddRoom"
        @search-room="callSearchRoom"
        @add-room="$emit('add-room')"
      >
        <template
          v-for="(idx, name) in $slots"
          #[name]="data"
        >
          <slot
            :name="name"
            v-bind="data"
          />
        </template>
      </rooms-search>
    </slot>

    <rooms-filters
      @reorder-rooms="reorderRooms"
    />
    <loader
      :show="loadingRooms && !rooms.length"
      type="rooms"
    >
      <template
        v-for="(idx, name) in $slots"
        #[name]="data"
      >
        <slot
          :name="name"
          v-bind="data"
        />
      </template>
    </loader>

    <div
      v-if="!loadingRooms && !rooms.length"
      class="vac-rooms-empty"
    >
      <slot name="rooms-empty">
        {{ $t('Salas vacías') }}
      </slot>
    </div>

    <!-- v-if="!loadingRooms" -->
    <div
      id="rooms-list"
      class="vac-room-list"
    >
      <div
        v-for="fRoom in reorderedFilteredRooms"
        :id="fRoom.roomId"
        :key="fRoom.roomId"
        class="vac-room-item"
        :class="{ 'vac-room-selected': selectedRoomId === fRoom.roomId }"
        @click="openRoom(fRoom)"
      >
        <room-content
          :current-user-id="currentUserId"
          :room="fRoom"
          :text-formatting="textFormatting"
          :link-options="linkOptions"
          :text-messages="textMessages"
          :room-actions="roomActions"
          @room-action-handler="$emit('room-action-handler', $event)"
        >
          <template
            v-for="(idx, name) in $slots"
            #[name]="data"
          >
            <slot
              :name="name"
              v-bind="data"
            />
          </template>
        </room-content>
      </div>
      <transition name="vac-fade-message">
        <div
          v-if="rooms.length && loadingRooms"
          id="infinite-loader-rooms"
        >
          <!-- :show="showLoader" -->
          <loader
            :show="true"
            :infinite="true"
            type="infinite-rooms"
          >
            <template
              v-for="(idx, name) in $slots"
              #[name]="data"
            >
              <slot
                :name="name"
                v-bind="data"
              />
            </template>
          </loader>
        </div>
      </transition>
      <div
        v-if="roomsLoaded && reorderedFilteredRooms.length > 10"
        v-intersect.quiet="onIntersect"
      />
    </div>
  </div>
</template>

<script>
  import Loader from '../../components/Loader/Loader'

  import RoomsSearch from './RoomsSearch/RoomsSearch'
  import RoomsFilters from './RoomsFilters/RoomsFilters.vue'
  import RoomContent from './RoomContent/RoomContent'

  import EventBus from '@/util/EventBus.js'
  import { ChatProps } from '@/classes/chat/chat-props.js'
  import moment from 'moment'

  export default {
    name: 'RoomsList',
    components: {
      Loader,
      RoomsSearch,
      RoomsFilters,
      RoomContent,
    },

    props: {
      chatProps: { type: Object, required: true, default: () => { return new ChatProps() } },
      currentUserId: { type: [String, Number], required: true },
      textMessages: { type: Object, required: true },
      showRoomsList: { type: Boolean, required: true },
      showSearch: { type: Boolean, required: true },
      showAddRoom: { type: Boolean, required: true },
      textFormatting: { type: Object, required: true },
      linkOptions: { type: Object, required: true },
      isMobile: { type: Boolean, required: true },
      rooms: { type: Array, required: true },
      loadingRooms: { type: Boolean, required: true },
      roomsLoaded: { type: Boolean, required: true },
      room: { type: Object, required: true },
      customSearchRoomEnabled: { type: [Boolean, String], default: false },
      roomActions: { type: Array, required: true },
      scrollDistance: { type: Number, required: true },
      routes: { type: Object },
    },

    emits: [
      'add-room',
      'search-room',
      'room-action-handler',
      'loading-more-rooms',
      'fetch-room',
      'fetch-more-rooms',
    ],

    data () {
      return {
        searthTerm: '',
        formatting: [],
        filteredRooms: this.rooms || [],
        reorderedFilteredRooms: [],
        observer: null,
        showLoader: true,
        loadingMoreRooms: false,
        selectedRoomId: '',
        opened: true,
        closed: false,
        pending: false,
        roomsOrders: {
          roomOrder: -1,
          roomPeriod: 7,
        },
        firstTimeClickLoadConversations: true,
      }
    },

    computed: {
      chatFilter () {
        return { opened: this.opened, closed: this.closed, pending: this.pending }
      },
      searchTags () {
        return {
          EMAIL: this.$t('Email'),
          NAME: this.$t('Nombre'),
          PHONE: this.$t('Teléfono'),
          SENDING: this.$t('Envío'),
          CLOSED: this.$t('Cerrado'),
          TAG: this.$t('Etiqueta'),
        }
      },
    },
    watch: {
      rooms: {
        deep: true,
        handler (newVal, oldVal) {
          this.reorderedFilteredRooms = this.applyFilters(this.rooms)
          if (newVal.length !== oldVal.length || this.roomsLoaded) {
            this.loadingMoreRooms = false
          }
        },
      },
      filteredRooms: {
        deep: true,
        handler (newVal, oldVal) {
          this.reorderedFilteredRooms = this.applyFilters(this.filteredRooms)
          if (newVal.length !== oldVal.length || this.roomsLoaded) {
            this.loadingMoreRooms = false
          }
        },
      },
      loadingRooms (val) {
        if (!val) {
          setTimeout(() => this.initIntersectionObserver())
        }
      },
      loadingMoreRooms (val) {
        this.$emit('loading-more-rooms', val)
      },
      roomsLoaded: {
        immediate: true,
        handler (val) {
          if (val) {
            this.loadingMoreRooms = false
            if (!this.loadingRooms) {
              this.showLoader = false
            }
          }
        },
      },
      room: {
        immediate: true,
        handler (val) {
          if (val && !this.isMobile) this.selectedRoomId = val.roomId
        },
      },
      routes: {
        deep: true,
        handler (val) {
          // eslint-disable-next-line vue/no-mutating-props
          this.chatProps.currentUserMsisdn = val.list[0]?.msisdn
        },
      },
    },
    mounted: function () {
      this.emitChatFilter()
      const interval = setInterval(() => {
        if (this.routes.list.length !== 0) {
          // eslint-disable-next-line vue/no-mutating-props
          this.chatProps.currentUserMsisdn = this.routes.list[0].msisdn
          this.changeCurrentMsisdn()
          clearInterval(interval)
        }
      }, 200)
      EventBus.$on('Whatsapp:reorderRooms', this.reorderRooms)
      EventBus.$on('hook:beforeDestroy', () => {
        EventBus.$off('Whatsapp:reorderRooms', this.reorderRooms)
      })
    },
    methods: {
      onIntersect (entries, observer, isIntersecting) {
        const hasItems = this.rooms.length > 0
        if (isIntersecting && hasItems) {
          // this.$emit('load-next-page', {
          //   opened: this.opened,
          //   closed: this.closed,
          //   pending: this.pending,
          // })
        }
      },
      changeCurrentMsisdn () {
        EventBus.$emit('ChangeCurrentUserMsisdn', this.chatProps.currentUserMsisdn)
      },
      applyFilters (rooms) {
        if (!this.roomsOrders) {
          return rooms
        }

        let filtered = [...rooms]
        filtered = filtered.sort((a, b) => {
          if (a.dateLastMessage > b.dateLastMessage) {
            return 1 * this.roomsOrders.roomOrder
          }
          if (a.dateLastMessage < b.dateLastMessage) {
            return -1 * this.roomsOrders.roomOrder
          }
          return 0
        })

        if (this.roomsOrders.roomPeriod !== null && this.roomsOrders.roomPeriod >= 0) {
          const period = this.roomsOrders.roomPeriod
          if ([301, 601].includes(period)) {
            // eslint-disable-next-line max-depth
            if (period === 301) { // This month
              const selectedDate = moment().format('YYYY-MM')
              filtered = filtered.filter(room => {
                return selectedDate === moment(room.dateLastMessage, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM')
              })
            }
            // eslint-disable-next-line max-depth
            if (period === 601) { // Last month
              const selectedDate = moment().add('-1', 'month').format('YYYY-MM')
              filtered = filtered.filter(room => {
                return selectedDate <= moment(room.dateLastMessage, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM')
              })
            }
          } else {
            const selectedDate = moment().add('-' + period, 'days').format('YYYY-MM-DD')
            filtered = filtered.filter(room => {
              return selectedDate <= moment(room.dateLastMessage, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD')
            })
          }
        }

        return filtered
      },
      emitChatFilter () {
        this.$emit('filter-chats', this.chatFilter)
      },
      setOpened () {
        this.opened = true
        this.closed = false
        this.pending = false
        this.emitChatFilter()
      },
      setClosed () {
        if (this.firstTimeClickLoadConversations) {
          this.firstTimeClickLoadConversations = false
          this.$emit('load-closed-conversations')
        }
        this.closed = true
        this.opened = false
        this.pending = false
        this.emitChatFilter()
      },
      setPending () {
        this.pending = true
        this.opened = false
        this.closed = false
        this.emitChatFilter()
      },
      initIntersectionObserver () {
        if (this.observer) {
          this.showLoader = true
          this.observer.disconnect()
        }

        const loader = this.$el.querySelector('#infinite-loader-rooms')

        if (loader) {
          const options = {
            root: this.$el.querySelector('#rooms-list'),
            rootMargin: `${this.scrollDistance}px`,
            threshold: 0,
          }

          this.observer = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting) {
              this.loadMoreRooms()
            }
          }, options)

          this.observer.observe(loader)
        }
      },

      filterRoomsInContactUserProp (items, prop, searchTerm, tag) {
        return items.filter(v => {
          if (!searchTerm || searchTerm === '') return false
          if (v.users[1].contact[prop] && this.formatString(v.users[1].contact[prop]).includes(this.formatString(searchTerm))) {
            this.addUniqueToArray(v.foundOnSearch, tag)
            return true
          }
          this.removeUniqueFromArray(v.foundOnSearch, tag)
          return false
        })
      },
      formatString (string) {
        return string
          .toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
      },
      filterByProp (items, prop, val, startsWith = false, tag = this.searchTags.NAME) {
        if (!val || val === '') return items

        return items.filter(v => {
          if (startsWith) {
            if (this.formatString(v[prop]).startsWith(this.formatString(val))) {
              this.addUniqueToArray(v.foundOnSearch, tag)
              return true
            }
            this.removeUniqueFromArray(v.foundOnSearch, tag)
            return false
          }
          if (this.formatString(v[prop]).includes(this.formatString(val))) {
            this.addUniqueToArray(v.foundOnSearch, tag)
            return true
          }
          this.removeUniqueFromArray(v.foundOnSearch, tag)
          return false
        })
      },
      filterRoomsClosedReason (items, searchTerm) {
        return items.filter(v => {
          if (!searchTerm || searchTerm === '') return false
          const closedReasons = v.users[1].contact.closedReasons
          if (closedReasons && closedReasons.length > 0 && this.formatString(closedReasons[0].reason).includes(this.formatString(searchTerm))) {
            this.addUniqueToArray(v.foundOnSearch, this.searchTags.CLOSED)
            return true
          }
          this.removeUniqueFromArray(v.foundOnSearch, this.searchTags.CLOSED)
          return false
        })
      },
      filterRoomsSending (items, searchTerm) {
        return items.filter(v => {
          if (!searchTerm || searchTerm === '') return false
          if (v.contactOnSendings && v.contactOnSendings.includes(searchTerm)) {
            this.addUniqueToArray(v.foundOnSearch, this.searchTags.SENDING)
            return true
          }
          this.removeUniqueFromArray(v.foundOnSearch, this.searchTags.SENDING)
          return false
        })
      },
      filterTags (items, searchTerm) {
        if (!searchTerm || searchTerm === '') return []
        const found = []
        for (const room of items) {
          if (room.users[1]?.contact.tagsNames) {
            // eslint-disable-next-line max-depth
            for (const tag of room.users[1].contact.tagsNames) {
              // eslint-disable-next-line max-depth
              if (tag?.toLowerCase().includes(searchTerm.toLowerCase())) {
                this.addUniqueToArray(room.foundOnSearch, this.searchTags.TAG)
                found.push(room)
              } else {
                this.removeUniqueFromArray(room.foundOnSearch, this.searchTags.TAG)
              }
            }
          }
        }
        return found
      },
      addUniqueToArray (arr, element) {
        if (!arr.includes(element)) {
          arr.push(element)
        }
      },
      removeUniqueFromArray (arr, element) {
        if (arr.includes(element)) {
          arr.splice(arr.indexOf(element), 1)
        }
      },
      callSearchRoom (ev) {
        this.filteredRooms = this.searchRoom(ev)
      },
      searchRoom (ev) {
        const rooms = [...this.rooms]
        if (ev && ev.target) {
          this.searchTerm = ev.target.value
        }

        if (this.customSearchRoomEnabled) {
          this.$emit('search-room', this.searchTerm)
        } else {
          if (!this.searchTerm || this.searchTerm === '') {
            rooms.forEach(i => { i.foundOnSearch = [] })
          }

          const foundByName = this.filterByProp(rooms, 'roomName', this.searchTerm, false, this.searchTags.NAME)
          const foundByPhone = this.filterRoomsInContactUserProp(rooms, 'msisdnClient', this.searchTerm, this.searchTags.PHONE)
          const foundByEmail = this.filterRoomsInContactUserProp(rooms, 'email', this.searchTerm, this.searchTags.EMAIL)
          const foundByTags = this.filterTags(rooms, this.searchTerm)
          const foundByClosedReason = this.filterRoomsClosedReason(rooms, this.searchTerm)
          const foundBySending = this.filterRoomsSending(rooms, this.searchTerm)

          const mergeResult = [
            ...foundByName,
            ...foundByPhone,
            ...foundByEmail,
            ...foundByTags,
            ...foundByClosedReason,
            ...foundBySending,
          ]
          const uniques = [
            ...new Map(mergeResult.map((item) => [item.roomId, item])).values(),
          ]

          // console.log('uniques', uniques)

          // this.filteredRooms = uniques
          return uniques
        }
      },
      openRoom (room) {
        EventBus.$emit('resetSearchWhatsappInbox')
        if (room.roomId === this.room.roomId && !this.isMobile) return
        if (!this.isMobile) this.selectedRoomId = room.roomId
        this.$emit('fetch-room', { room })
      },
      loadMoreRooms () {
        if (this.loadingMoreRooms) return

        if (this.roomsLoaded) {
          this.loadingMoreRooms = false
          this.showLoader = false
          return
        }

        this.$emit('fetch-more-rooms')
        this.loadingMoreRooms = true
      },
      reorderRooms (roomsOrders) {
        this.roomsOrders = roomsOrders || this.roomsOrders
        this.filteredRooms = this.searchRoom()
        // this.reorderedFilteredRooms = this.applyFilters(this.rooms)
      },
    },
  }
</script>

<style>
button.btn-conversaciones-whats {
  font-size: 0.8em !important;
}
button.btn-conversaciones-whats.v-btn--active {
  color: #fff !important;
  background-color: #1e88e5 !important;
}
</style>
